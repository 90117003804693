import styled from 'styled-components';
import { ContainerColumnStart, ContainerColumnStartWithoutWidth } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { black, blue, blueIcon } from '../../styles/Variables';

export const RegionContainer = styled(ContainerColumnStart)`
    margin: 0 auto;

    @media ${deviceMax.tablet} {
        width: calc(100% - 30px);
        padding-bottom: 4rem;
    }
`;

export const RegionTitle = styled.h1`
    color: ${blue};
    font-size: 2.5rem;
    letter-spacing: 0.025em;
    line-height: 2.75rem;
    margin-bottom: 1rem;
    font-family: 'NeueHelveticaPro77CondensedBold', Helvetica, sans-serif;
`;

export const RegionDescription = styled.p`
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    margin-bottom: 10px;
    padding: 0;
`;

export const ContactWrapper = styled.div`
    width: 100%;
    height: 155px;
    margin-top: 10px;
    display: flex;

    @media (max-width: 589px) {
        flex-direction: column;
    }
`;

export const ContactImage = styled.img`
    width: 270px;
    height: 155px;
`;

export const ContactDetailsWrapper = styled(ContainerColumnStartWithoutWidth)``;

export const ContactDetailsSection = styled(ContainerColumnStartWithoutWidth)`
    margin: 0 0 20px 10px;
    padding: 0;

    * {
        margin: 0;
        padding: 0;
        font-family: Arial, Helvetica, sans-serif;
    }

    @media (max-width: 589px) {
        margin: 10px 0 20px 0;
    }
`;

export const ContactDetailsSectionLabel = styled.h3`
    font-weight: 700;
`;

interface TextType {
    readonly isEmail?: boolean;
}

export const ContactDetailsSectionText = styled.p<TextType>`
    a {
        color: ${(props) => (props.isEmail ? blueIcon : black)};
        text-decoration: none;
    }
`;
